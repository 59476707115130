import { createFileRoute } from '@tanstack/react-router';

import { useMe } from '@/api/me';

const Component = () => {
  const { data } = useMe();

  return <div>Hi {data.firstName}, nice to see you again!</div>;
};

export const Route = createFileRoute('/_authenticated/_sidebar-layout/')({
  preload: true,
  component: Component,
});
