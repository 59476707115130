import { Header } from '@/components/layout/header';
import { Sidebar } from '@/components/layout/sidebar';

export const MainLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="relative flex h-screen flex-col bg-background-lighter">
      <Header />
      <div className="flex h-[calc(100vh-108px)] flex-1 bg-background-lighter pl-[46px] pt-[41px]">
        <Sidebar />
        <main className="flex-1 overflow-auto pl-[27px] pr-[46px]">{children}</main>
      </div>
    </div>
  );
};
