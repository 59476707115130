/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LogoutImport } from './routes/logout'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthLayoutImport } from './routes/_auth-layout'
import { Route as AuthenticatedSidebarLayoutImport } from './routes/_authenticated/_sidebar-layout'
import { Route as AuthenticatedSidebarLayoutIndexImport } from './routes/_authenticated/_sidebar-layout/index'
import { Route as AuthenticatedSidebarLayoutUsersImport } from './routes/_authenticated/_sidebar-layout/users'
import { Route as AuthenticatedSidebarLayoutContactImport } from './routes/_authenticated/_sidebar-layout/contact'
import { Route as AuthenticatedSidebarLayoutAboutImport } from './routes/_authenticated/_sidebar-layout/about'
import { Route as AuthenticatedSidebarLayoutEstatesIndexImport } from './routes/_authenticated/_sidebar-layout/estates/index'

// Create Virtual Routes

const AuthLayoutRegisterLazyImport = createFileRoute('/_auth-layout/register')()
const AuthLayoutPasswordResetLazyImport = createFileRoute(
  '/_auth-layout/password-reset',
)()
const AuthLayoutLoginLazyImport = createFileRoute('/_auth-layout/login')()
const AuthenticatedSidebarLayoutProfileLazyImport = createFileRoute(
  '/_authenticated/_sidebar-layout/profile',
)()

// Create/Update Routes

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_auth-layout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutRegisterLazyRoute = AuthLayoutRegisterLazyImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthLayoutRoute,
} as any).lazy(() =>
  import('./routes/_auth-layout/register.lazy').then((d) => d.Route),
)

const AuthLayoutPasswordResetLazyRoute =
  AuthLayoutPasswordResetLazyImport.update({
    id: '/password-reset',
    path: '/password-reset',
    getParentRoute: () => AuthLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_auth-layout/password-reset.lazy').then((d) => d.Route),
  )

const AuthLayoutLoginLazyRoute = AuthLayoutLoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthLayoutRoute,
} as any).lazy(() =>
  import('./routes/_auth-layout/login.lazy').then((d) => d.Route),
)

const AuthenticatedSidebarLayoutRoute = AuthenticatedSidebarLayoutImport.update(
  {
    id: '/_sidebar-layout',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedSidebarLayoutIndexRoute =
  AuthenticatedSidebarLayoutIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutProfileLazyRoute =
  AuthenticatedSidebarLayoutProfileLazyImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/profile.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutUsersRoute =
  AuthenticatedSidebarLayoutUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutContactRoute =
  AuthenticatedSidebarLayoutContactImport.update({
    id: '/contact',
    path: '/contact',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutAboutRoute =
  AuthenticatedSidebarLayoutAboutImport.update({
    id: '/about',
    path: '/about',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesIndexRoute =
  AuthenticatedSidebarLayoutEstatesIndexImport.update({
    id: '/estates/',
    path: '/estates/',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/estates/index.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth-layout': {
      id: '/_auth-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_sidebar-layout': {
      id: '/_authenticated/_sidebar-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedSidebarLayoutImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_auth-layout/login': {
      id: '/_auth-layout/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLayoutLoginLazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/password-reset': {
      id: '/_auth-layout/password-reset'
      path: '/password-reset'
      fullPath: '/password-reset'
      preLoaderRoute: typeof AuthLayoutPasswordResetLazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/register': {
      id: '/_auth-layout/register'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof AuthLayoutRegisterLazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authenticated/_sidebar-layout/about': {
      id: '/_authenticated/_sidebar-layout/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutAboutImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/contact': {
      id: '/_authenticated/_sidebar-layout/contact'
      path: '/contact'
      fullPath: '/contact'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutContactImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/users': {
      id: '/_authenticated/_sidebar-layout/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutUsersImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/profile': {
      id: '/_authenticated/_sidebar-layout/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutProfileLazyImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/': {
      id: '/_authenticated/_sidebar-layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/': {
      id: '/_authenticated/_sidebar-layout/estates/'
      path: '/estates'
      fullPath: '/estates'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutRouteChildren {
  AuthLayoutLoginLazyRoute: typeof AuthLayoutLoginLazyRoute
  AuthLayoutPasswordResetLazyRoute: typeof AuthLayoutPasswordResetLazyRoute
  AuthLayoutRegisterLazyRoute: typeof AuthLayoutRegisterLazyRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutLoginLazyRoute: AuthLayoutLoginLazyRoute,
  AuthLayoutPasswordResetLazyRoute: AuthLayoutPasswordResetLazyRoute,
  AuthLayoutRegisterLazyRoute: AuthLayoutRegisterLazyRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

interface AuthenticatedSidebarLayoutRouteChildren {
  AuthenticatedSidebarLayoutAboutRoute: typeof AuthenticatedSidebarLayoutAboutRoute
  AuthenticatedSidebarLayoutContactRoute: typeof AuthenticatedSidebarLayoutContactRoute
  AuthenticatedSidebarLayoutUsersRoute: typeof AuthenticatedSidebarLayoutUsersRoute
  AuthenticatedSidebarLayoutProfileLazyRoute: typeof AuthenticatedSidebarLayoutProfileLazyRoute
  AuthenticatedSidebarLayoutIndexRoute: typeof AuthenticatedSidebarLayoutIndexRoute
  AuthenticatedSidebarLayoutEstatesIndexRoute: typeof AuthenticatedSidebarLayoutEstatesIndexRoute
}

const AuthenticatedSidebarLayoutRouteChildren: AuthenticatedSidebarLayoutRouteChildren =
  {
    AuthenticatedSidebarLayoutAboutRoute: AuthenticatedSidebarLayoutAboutRoute,
    AuthenticatedSidebarLayoutContactRoute:
      AuthenticatedSidebarLayoutContactRoute,
    AuthenticatedSidebarLayoutUsersRoute: AuthenticatedSidebarLayoutUsersRoute,
    AuthenticatedSidebarLayoutProfileLazyRoute:
      AuthenticatedSidebarLayoutProfileLazyRoute,
    AuthenticatedSidebarLayoutIndexRoute: AuthenticatedSidebarLayoutIndexRoute,
    AuthenticatedSidebarLayoutEstatesIndexRoute:
      AuthenticatedSidebarLayoutEstatesIndexRoute,
  }

const AuthenticatedSidebarLayoutRouteWithChildren =
  AuthenticatedSidebarLayoutRoute._addFileChildren(
    AuthenticatedSidebarLayoutRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedSidebarLayoutRoute: typeof AuthenticatedSidebarLayoutRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedSidebarLayoutRoute: AuthenticatedSidebarLayoutRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedSidebarLayoutRouteWithChildren
  '/logout': typeof LogoutRoute
  '/login': typeof AuthLayoutLoginLazyRoute
  '/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/register': typeof AuthLayoutRegisterLazyRoute
  '/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/estates': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedRouteWithChildren
  '/logout': typeof LogoutRoute
  '/login': typeof AuthLayoutLoginLazyRoute
  '/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/register': typeof AuthLayoutRegisterLazyRoute
  '/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/estates': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth-layout': typeof AuthLayoutRouteWithChildren
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/logout': typeof LogoutRoute
  '/_authenticated/_sidebar-layout': typeof AuthenticatedSidebarLayoutRouteWithChildren
  '/_auth-layout/login': typeof AuthLayoutLoginLazyRoute
  '/_auth-layout/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/_auth-layout/register': typeof AuthLayoutRegisterLazyRoute
  '/_authenticated/_sidebar-layout/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/_authenticated/_sidebar-layout/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/_authenticated/_sidebar-layout/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/_authenticated/_sidebar-layout/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/_authenticated/_sidebar-layout/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/_authenticated/_sidebar-layout/estates/': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/logout'
    | '/login'
    | '/password-reset'
    | '/register'
    | '/about'
    | '/contact'
    | '/users'
    | '/profile'
    | '/'
    | '/estates'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/logout'
    | '/login'
    | '/password-reset'
    | '/register'
    | '/about'
    | '/contact'
    | '/users'
    | '/profile'
    | '/'
    | '/estates'
  id:
    | '__root__'
    | '/_auth-layout'
    | '/_authenticated'
    | '/logout'
    | '/_authenticated/_sidebar-layout'
    | '/_auth-layout/login'
    | '/_auth-layout/password-reset'
    | '/_auth-layout/register'
    | '/_authenticated/_sidebar-layout/about'
    | '/_authenticated/_sidebar-layout/contact'
    | '/_authenticated/_sidebar-layout/users'
    | '/_authenticated/_sidebar-layout/profile'
    | '/_authenticated/_sidebar-layout/'
    | '/_authenticated/_sidebar-layout/estates/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LogoutRoute: typeof LogoutRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LogoutRoute: LogoutRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth-layout",
        "/_authenticated",
        "/logout"
      ]
    },
    "/_auth-layout": {
      "filePath": "_auth-layout.tsx",
      "children": [
        "/_auth-layout/login",
        "/_auth-layout/password-reset",
        "/_auth-layout/register"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_sidebar-layout"
      ]
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/_authenticated/_sidebar-layout": {
      "filePath": "_authenticated/_sidebar-layout.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_sidebar-layout/about",
        "/_authenticated/_sidebar-layout/contact",
        "/_authenticated/_sidebar-layout/users",
        "/_authenticated/_sidebar-layout/profile",
        "/_authenticated/_sidebar-layout/",
        "/_authenticated/_sidebar-layout/estates/"
      ]
    },
    "/_auth-layout/login": {
      "filePath": "_auth-layout/login.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/password-reset": {
      "filePath": "_auth-layout/password-reset.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/register": {
      "filePath": "_auth-layout/register.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_authenticated/_sidebar-layout/about": {
      "filePath": "_authenticated/_sidebar-layout/about.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/contact": {
      "filePath": "_authenticated/_sidebar-layout/contact.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/users": {
      "filePath": "_authenticated/_sidebar-layout/users.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/profile": {
      "filePath": "_authenticated/_sidebar-layout/profile.lazy.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/": {
      "filePath": "_authenticated/_sidebar-layout/index.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/estates/": {
      "filePath": "_authenticated/_sidebar-layout/estates/index.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    }
  }
}
ROUTE_MANIFEST_END */
