import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import { createRootRouteWithContext, Outlet, ScrollRestoration } from '@tanstack/react-router';
import { Body, Head, Html, Scripts } from '@tanstack/start';

import { RouterContext } from '@/lib/router';

import 'react-toastify/dist/ReactToastify.css';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

const RootComponent = () => {
  return (
    <RootDocument>
      <Outlet />
      <TanStackRouterDevtools />
      <ToastContainer position="bottom-right" />
    </RootDocument>
  );
};

export const Route = createRootRouteWithContext<RouterContext>()({
  meta: () => [
    {
      charSet: 'utf-8',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      title: 'TanStack Start Starter',
    },
  ],
  component: RootComponent,
});

const RootDocument = ({ children }: { children: React.ReactNode }) => {
  return (
    <Html>
      <Head>{/* <Meta /> */}</Head>
      <Body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </Body>
    </Html>
  );
};
