import { Link, useNavigate } from '@tanstack/react-router';
import { BellIcon, LogOutIcon, UserIcon } from 'lucide-react';

import { useMe } from '@/api/me';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Logo } from '@/components/ui/logo';
import { useLogOut } from '@/features/auth/api/logout';
import { getAvatarFallback } from '@/utils/avatar';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

export const Header = () => {
  const { logout } = useLogOut();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    throw navigate({ to: '/login' });
  };
  const me = useMe();

  return (
    <header className="flex h-[108px] items-center justify-between rounded-lg border-b border-gray-200 bg-primary-lighter py-[25px] pl-10 pr-14">
      <Logo />
      <div className="flex items-center gap-[21px]">
        <BellIcon size={37} />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Avatar className="cursor-pointer">
              <AvatarImage src={`/images/${me.data.images?.mediaId}`} alt="avatar" />
              <AvatarFallback>{getAvatarFallback(me.data)}</AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-44">
            <DropdownMenuItem asChild>
              <Link to="/profile">
                <UserIcon />
                Profile
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link onClick={handleLogOut} preload={false}>
                <LogOutIcon />
                Logout
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};
