import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

type EstatesQueryOptions = {
  queryConfig?: QueryConfig<typeof getEstatesQueryOptions>;
};

export const getEstates = () => {
  return extractData(
    api.estates.get({
      query: {
        filterOwn: false,
      },
    })
  );
};

export const getEstatesQueryOptions = () => {
  return queryOptions({
    queryKey: ['estates'],
    queryFn: getEstates,
  });
};

export const ensureEstatesQueryData = ({ context: { queryClient } }: LoaderProps) => {
  return queryClient.ensureQueryData(getEstatesQueryOptions());
};

export const useEstates = ({ queryConfig }: EstatesQueryOptions = {}) => {
  return useSuspenseQuery({ ...getEstatesQueryOptions(), ...queryConfig });
};

const _sleep = (timeout: number) => new Promise((r) => setTimeout(() => r(''), timeout));
const _SLEEP_DELAY = 1000;

export const useEstateFiltersOptions = () =>
  useSuspenseQuery({
    queryKey: ['form-options'],
    // TODO: Replace with api call
    queryFn: async () => {
      await _sleep(_SLEEP_DELAY);
      return {
        amountOfRooms: [
          {
            id: '1',
            title: '1+',
          },
          {
            id: '2',
            title: '2+',
          },
          {
            id: '3',
            title: '3+',
          },
          {
            id: '4',
            title: '4+',
          },
          {
            id: '5',
            title: '5+',
          },
        ],
        marketingTypes: [
          {
            id: '1',
            title: 'Sale',
          },
          {
            id: '2',
            title: 'Rent',
          },
        ],
        usages: [
          {
            id: '1',
            title: 'Residential',
          },
          {
            id: '2',
            title: 'Commercial',
          },
        ],
        propertyTypes: [
          {
            id: '1',
            title: 'Apartment',
          },
          {
            id: '2',
            title: 'House',
          },
        ],
        heating: [
          {
            id: '1',
            title: 'Oil',
          },
          {
            id: '2',
            title: 'Gas',
          },
          {
            id: '3',
            title: 'Central',
          },
        ],
        flooring: [
          {
            id: '1',
            title: 'Wood',
          },
          {
            id: '2',
            title: 'Tile',
          },
          {
            id: '3',
            title: 'Central',
          },
        ],
        glazing: [
          {
            id: '1',
            title: 'Double',
          },
          {
            id: '2',
            title: 'Triple',
          },
        ],
        additionalFeatures: [
          {
            id: '1',
            title: 'Balcony',
          },
          {
            id: '2',
            title: 'Terrace',
          },
          {
            id: '3',
            title: 'Garden',
          },
          {
            id: '4',
            title: 'Elevator',
          },
          {
            id: '5',
            title: 'Parking space',
          },
          {
            id: '6',
            title: 'Basement',
          },
        ],
      };
    },
  });
