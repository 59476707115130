import { HomeIcon, LayoutDashboardIcon, User2Icon } from 'lucide-react';

import { SidebarLink } from '@/components/ui/sidebar-link';

export const Sidebar = () => {
  return (
    <aside className="flex w-[273px] flex-col bg-transparent">
      <nav className="flex flex-col gap-1">
        <SidebarLink to="/">
          <LayoutDashboardIcon /> Dashboard
        </SidebarLink>
        <SidebarLink to="/estates">
          <HomeIcon /> Properties
        </SidebarLink>
        <SidebarLink to="/profile">
          <User2Icon /> Profile
        </SidebarLink>
      </nav>
    </aside>
  );
};
