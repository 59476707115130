import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';

import { auth } from '@/lib/firebase';

import { useDeleteAuthCookies } from './auth-cookies';
import { getAuthenticatedQueryOptions } from './authenticated';

export const useLogOut = () => {
  const queryClient = useQueryClient();
  const { deleteAuthCookie } = useDeleteAuthCookies();

  const signOutMutation = useMutation({
    mutationFn: () => signOut(auth),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: getAuthenticatedQueryOptions().queryKey });
    },
  });

  const logout = async () => {
    deleteAuthCookie.mutate();
    signOutMutation.mutate();
  };

  return { logout };
};
