import { Treaty, treaty } from '@elysiajs/eden';
import { Api } from 'realtiq-api/src/routes';

import { env } from '@/constants/env';

export const api = treaty<Api>(env.API_URL, {
  fetch: {
    credentials: 'include',
  },
});

export const extractData = async <T extends Record<number, unknown>>(
  promise: Promise<Treaty.TreatyResponse<T>>
) => {
  const { data, error } = await promise;

  if (!data) {
    if (error && typeof error.value === 'string') throw new Error(error.value);

    throw new Error('Something went wrong');
  }

  return data;
};
