import { createFileRoute, redirect } from '@tanstack/react-router';

import { ensureMeQueryData } from '@/api/me';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: ({ context, location }) => {
    if (!context.authenticated) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  loader: ensureMeQueryData,
});
